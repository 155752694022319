#root {
  margin: auto;
}

#footer{
  position:fixed; 
  bottom:0px; 
  width:100%; 
  height: 40px; 
  background-color:rgb(51, 51, 51); 
  color:#FFF; 
  text-align:center; 
  padding-top:9px;
  font-size: 10pt;
}

.spacer{
  clear:both;
}

.main-header {
  /* min-height: 60px; */
}

#geo-logo {
  font: "Helvetica Neue,Helvetica,Arial,sans-serif";
  font-size: 14pt;
  margin:15px 0px 10px 0px;
  /* font-weight:bold; */
  text-align:left;
  /* padding: 10px 0px 0px 20px; */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.startDate input{
  width: 250px;
  height: 33px;
}

.endDate input{
  width: 250px;
  height: 33px;
}

.logDatetime input{
  margin-left: 15px;
  width: 183px;
  height: 33px;
}

.jsgrid-cell.tnkGridCell {
  overflow:hidden;
}

.grid-default-dimensions {
  width: 90%;
  margin: 20px 5%;
}

#referenceInputForm .referenceField {
  padding: 10px 0px 0px 0px;
}

.hidden {
  display:none;
}

.displayed, .displayedBlock {
  display:block;
}

.displayedInline {
  display:inline;
}

.gridLink{
  color:#00F;
}

#detailsModalContainer .modal-dialog {
  max-width: 800px;
}

#submitReferences{
  margin-bottom:60px;
}

#saveReferenceBlock{
  margin-bottom:60px;
}